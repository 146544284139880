<template>
  <div>
    <Header :headerIndex="1"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner1.png" alt="" />
      <div class="about_box1">
        <div class="about_text1 max_1440">
          <div v-html="info.detail">
           <!-- {{ info.detail }} -->
          </div>
        </div>
      </div>

      <div v-for="(item,index) in menulist">
      <div :id="item.id" class="white_box" v-if="index%2==0">
        <div class="about_inner max_1440 flex-box">
          <img v-show="item.id == 1" class="img" :src="item.slow_image" alt="" />
          <div class="flex-grow-1">
            <div class="flex-box">
              <img class="icon" :src="item.iconed_image" alt="" />
              <div class="title">{{item.name}}</div>
            </div>
            <div class="desc" v-html="item.detail">
              <!-- {{ item.detail }} -->
            </div>
          </div>
        </div>
      </div>

      <div :id="item.id" class="black_box" v-else>
        <div class="about_inner max_1440 flex-box flex-col-start">
          <div class="flex-grow-1">
            <div class="flex-box">
              <img class="icon" :src="item.iconed_image" alt="" />
              <div class="title">{{item.name}}</div>
            </div>
            <div class="desc" v-html="item.detail">
              <!-- {{ item.detail }} -->
            </div>
          </div>
          <!-- <img class="img" :src="item.slow_image" alt="" /> -->
        </div>
      </div>
    </div>
      <!-- <div class="white_box">
        <div class="about_inner max_1440 flex-box flex-col-start">
          <img class="img" src="../assets/static/icon/icon_about3.png" alt="" />
          <div class="flex-grow-1">
            <div class="flex-box">
              <img class="icon" src="../assets/static/icon/icon_menu3_on.png" alt="" />
              <div class="title">PLAN</div>
            </div>
            <div class="desc">
              RWF is committed to developing professional and reliable products and services globally, focusing on supply chain
              advantages, with innovation and creation as the core, professional technology and tools as assistance, and
              comprehensively leading high-end luxury paint film customization services
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="black_box">
        <div class="about_inner max_1440 flex-box flex-col-start">
          <div class="flex-grow-1">
            <div class="flex-box">
              <img class="icon" src="../assets/static/icon/icon_menu4_on.png" alt="" />
              <div class="title">AFTER SALES</div>
            </div>
            <div class="desc">
              Quality commitment: PET color changing film products have a free after-sales warranty of 2 years, TPU color changing
              film products have a free after-sales warranty of 3 years, TPU car clothing products have a free after-sales
              warranty of 5 years, and RWF promises to provide a sub warranty for every official factory genuine product! If the
              construction service unit is unable to provide you with the official genuine electrical product sub warranty of RWF
              automotive film, it will be considered a fake; And RWF is not responsible for the scope of its commitment to
              after-sales service, product quality assurance, and other contents! And you have the right to refuse to pay, and to
              report your false sale behavior with the right to receive a fine of one gain and three losses.
            </div>
          </div>
          <img class="img" src="../assets/static/icon/icon_about4.png" alt="" />
        </div>
      </div> -->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import api from '../api';
export default {
  // metaInfo() {
  //   return {
  //     title: 'About',
  //     meta: [{
  //       name: 'RWF About',
  //       content: 'RWF汽车贴膜'
  //     }]
  //   }
  // },
  components: {},
  data(){
    return{
      menulist:[],
      info:[]
    }
  },
  methods:{
    getmenu(){
      api.doGet('/api/banner/getmenu',{},(res)=>{
        if(res.code == 200){
          this.menulist = res.data
          console.log(res.data)
        }
      })
    },
    getinfo(){
      api.doGet('/api/banner/getinfo',{},(res)=>{
          console.log(res);
          if(res.code == 200){
            this.info = res.data
          }
      })
    },
  },
  created(){
    this.getmenu()
    this.getinfo()
  }
};
</script>
